<script>
  export let version;
</script>

<style lang="scss" scoped>
  section {
    font-weight: 200;
    margin-top: 2em;
  }
</style>

<section name="revision">
  <p>Revision: {version}</p>
</section>
