<script>
  /**
   * tempo
   * © 2020, Hendrik Runte
   **/

  import { version } from './lib/version.js';
  import Revision from './components/Revision.svelte';
  import res from './lib/res.js';

  window.console.debug(
    '%cThis is assembled with svelte.',
    'background-color: fuchsia; color: black; padding: 0 1em'
  );
</script>

<!--
	Mind the 'global' attribute!
	This is needed to let 'prependData' work.
	See rollup.config.js
-->
<style global lang="scss">
</style>

<header>
  <h1>
    SizeOMeter
    <span class="portrait">(portrait)</span><span
      class="landscape">(landscape)</span>
  </h1>
</header>
<main>
  <div class="group">
    <dl>
      <dt>outerWidth</dt>
      <dd>{window.outerWidth}px</dd>
    </dl>
    <dl>
      <dt>outerHeight</dt>
      <dd>{window.outerHeight}px</dd>
    </dl>
  </div>

  <div class="group">
    <dl>
      <dt>innerWidth</dt>
      <dd>{window.innerWidth}px</dd>
    </dl>
    <dl>
      <dt>innerHeight</dt>
      <dd>{window.innerHeight}px</dd>
    </dl>
  </div>

  <div class="group">
    <dl>
      <dt>screen.width</dt>
      <dd>{window.screen.width}px</dd>
    </dl>
    <dl>
      <dt>screen.height</dt>
      <dd>{window.screen.height}px</dd>
    </dl>
  </div>

  <div class="group">
    <dl>
      <dt>screen.availWidth</dt>
      <dd>{window.screen.availWidth}px</dd>
    </dl>
    <dl>
      <dt>screen.availHeight</dt>
      <dd>{window.screen.availHeight}px</dd>
    </dl>
  </div>

  <div class="group">
    <dl>
      <dt>screen.colorDepth</dt>
      <dd>{window.screen.colorDepth}</dd>
    </dl>
    <dl>
      <dt>screen.pixelDepth</dt>
      <dd>{window.screen.pixelDepth}</dd>
    </dl>
  </div>

  <div class="group">
    <dl>
      <dt>devicePixelRatio</dt>
      <dd>{res.dppx()}x</dd>
    </dl>
    <dl>
      <dt>dpi</dt>
      <dd>{res.dpi()}</dd>
    </dl>
  </div>
</main>

<Revision {version} />
